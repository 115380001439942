// Overrides
@if $bulmaswatch-import-font {
    @import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);
}

.section {
  background-color: $body-background-color;
}

.hero {
  background-color: $body-background-color;
}

.button {
  &.is-hovered,
  &:hover {
    background-color: darken($button-background-color, 3%);
  }
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      &.is-hovered,
      &:hover {
        background-color: darken($color, 3%);
      }
    }
  }

  &.is-loading:after {
    border-color: transparent transparent $grey-light $grey-light;
  }
}

.label {
  color: $grey-lighter;
}

.notification {
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      a:not(.button) {
        color: $color-invert;
        text-decoration: underline;
      }
    }
  }
}

.card {
  border: 1px solid $border;
  border-radius: $radius;

  .card-image {
    img {
      border-radius: $radius $radius 0 0;
    }
  }

  .card-header {
    border-radius: $radius $radius 0 0;
  }

  .card-footer,
  .card-footer-item {
    border-width: 1px;
    border-color: $border;
  }
}

.modal-card-body {
  background-color: $body-background-color;
}

.navbar {
  &.is-transparent {
    background-color: transparent;
  }

  @include touch {
    .navbar-menu {
      background-color: transparent;
    }
  }

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      @include touch {
        .navbar-item,
        .navbar-link {
          color: $color-invert;

          &.is-active {
            color: rgba($color-invert, 0.7);
          }
        }

        .navbar-burger {
          span {
            background-color: $color-invert;
          }
        }
      }
    }
  }
}

.hero {
  .navbar {
    .navbar-dropdown {
      .navbar-item {
        color: $grey-lighter;
      }
    }
  }
}
