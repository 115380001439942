////////////////////////////////////////////////
// SUPERHERO
////////////////////////////////////////////////
$grey-darker:  #1f2d3b;
$grey-dark:    #2B3E50;
$grey:         #4E5D6C;
$grey-light:   #8694a4;
$grey-lighter: #dee5ed;

$orange:       #DF691A;
$yellow:       #f0ad4e;
$green:        #5cb85c;
$blue:         #5bc0de;
$red:          #d9534f;

$primary: $orange !default;

$dark: darken($grey-darker, 3);

$title-color: $grey-lighter;
$title-weight: 400;
$subtitle-strong-color: $grey-lighter;
$subtitle-color: darken($grey-lighter, 10);
$subtitle-strong-color: darken($grey-lighter, 10);

$background: $grey-dark;
$body-background-color: $grey-darker;
$footer-background-color: $background;

$border: $grey;

$family-sans-serif: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;

$text: $grey-lighter;
$text-strong: darken($grey-lighter, 10);
$text-light: $grey-light;

$box-background-color: $background;

$card-shadow: none;
$card-background-color: darken($body-background-color, 1);
$card-header-box-shadow: none;
$card-header-background-color: darken($body-background-color, 3);
$card-footer-background-color: darken($body-background-color, 3);

$link: $grey-light;
$link-hover: $grey-lighter;
$link-focus: $grey-lighter;
$link-active: $grey-lighter;

$button-color: $grey-lighter;
$button-background-color: $grey;
$button-border-color: $grey;

$button-hover-border: transparent;
$button-active-border-color: transparent;

$radius: 0;
$radius-small: 0;

$input-hover-color: $link-hover;

$table-color: $text;
$table-head-color: $grey-lighter;
$table-background-color: $grey-dark;
$table-cell-border: 1px solid $grey;
$table-row-hover-background-color: $grey-darker;
$table-striped-row-even-background-color: $grey-darker;
$table-striped-row-even-hover-background-color: lighten($grey-darker, 4);

$navbar-background-color: $background;
$navbar-item-color: $text;
$navbar-item-hover-color: $grey-light;
$navbar-item-active-color: $primary;
$navbar-item-hover-background-color: rgba($grey-darker, 0.1);
$navbar-item-active-background-color: rgba($grey-darker, 0.1);
$navbar-dropdown-item-hover-color: $grey-light;
$navbar-dropdown-item-active-color: $primary;
$navbar-dropdown-background-color: $background;
$navbar-dropdown-arrow: currentColor;

$dropdown-content-background-color: $background;
$dropdown-item-color: $text;
$dropdown-item-hover-color: $text-light;

$tabs-toggle-link-active-background-color: $background;
$tabs-toggle-link-active-border-color: $border;
$tabs-toggle-link-active-color: #FFF;
$tabs-boxed-link-active-background-color: $body-background-color;

$pagination-color: $link;
$pagination-border-color: $border;

$bulmaswatch-import-font: true !default;
